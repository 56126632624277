import './style/App.css';
import './style/index.css';
import './style/Animations.css';
import { ProjectRoutes } from './routes/Routes';

function App() {
  return (
    <>
      <ProjectRoutes/>
    </>
  );
}

export default App;
